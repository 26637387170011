import { Fragment, useEffect, useState } from 'react'
import { formatISO, isDate } from 'date-fns'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Box,
  DateTimePicker,
  Icon,
  Modal,
  MultiSelectControl,
  RadioboxGroup,
  Select,
  Text,
  TextEditor,
  TextInput,
  Tooltip,
  useNotify
} from '@cutover/react-ui'
import { CustomFieldUserSelect } from './custom-fiield-user-select'
import { SearchableCustomField } from './searchable-custom-field'
import { useAccount } from 'main/services/api/data-providers/account/account-data'
import { useLanguage } from 'main/services/hooks'
import {
  CustomField,
  CustomFieldDisplayType,
  CustomFieldUser,
  FieldValue,
  TaskListTask
} from 'main/services/queries/types'
import { FieldOptionPayload, useFieldOptionsMutation } from 'main/services/queries/use-custom-fields'
import { dynamicVariablesTooltipLabelHelper } from '../form/dynamic-variables-tooltip-label-helper'
import { TaskSelectField } from '../form/task-select'
import { StreamModel, TaskTypeModel } from 'main/data-access'
import { TaskModel } from 'main/data-access/models/task-model'

type CustomFieldFormProps = {
  errors?: object
  customFields: CustomField[]
  customFieldUsers?: CustomFieldUser[]
  namePrefix?: string
  taskLookup?: Record<number, TaskListTask>
  disabled?: boolean
  readOnly?: boolean
  alwaysNotRequired?: boolean // for bulk edit custom field form, we don't any of the custom field fields to be required
  timezone?: string | null // for date-time-picker custom fields to have timezone offset info on the label
}

export const CustomFieldForm = ({
  customFields,
  customFieldUsers,
  namePrefix = '',
  disabled,
  readOnly,
  alwaysNotRequired = false,
  errors,
  timezone
}: CustomFieldFormProps) => {
  const { t } = useLanguage('customFields')
  const { account } = useAccount()
  const { register, control, formState, getValues, watch, setValue } = useFormContext()
  const { dirtyFields } = formState

  const errorIds = Object.keys(errors || {})
  const notify = useNotify()
  const fieldOptionsMutation = useFieldOptionsMutation()
  const [isOptionConfirmModalOpen, setOptionConfirmModalOpen] = useState(false)
  const [optionCustomField, setOptionCustomField] = useState<CustomField | null>(null)
  const [optionValue, setOptionValue] = useState<string | null>(null)
  const fieldValues = watch(`${namePrefix}field_values`)
  const { contents: taskLookup } = TaskModel.useGetAllLoadable()
  const { contents: taskTypeLookup } = TaskTypeModel.useGetLookupLoadable()
  const { contents: streamLookup } = StreamModel.useGetLookupLoadable()

  const closeOptionConfirmModal = () => {
    setOptionConfirmModalOpen(false)
    setOptionCustomField(null)
    setOptionValue(null)
  }

  const showOptionConfirmModal = (customField: CustomField, value: string) => {
    setOptionConfirmModalOpen(true)
    setOptionCustomField(customField)
    setOptionValue(value)
  }

  const handleOptionCreate = () => {
    if (optionCustomField && optionValue) {
      mutateCustomFieldOption(optionCustomField.id, optionValue)
    }
  }

  const mutateCustomFieldOption = (customFieldId: number, value: string) => {
    const data: FieldOptionPayload = {
      account_slug: account?.slug,
      field_option: {
        custom_field_id: customFieldId,
        name: value
      }
    }

    fieldOptionsMutation.mutate(data, {
      onSuccess: () => {
        notify.success(t('fieldOptions.saved'))
        closeOptionConfirmModal()
      },
      onError: () => {
        notify.warning(t('fieldOptions.uniqueWarningDescription'), {
          title: t('fieldOptions.uniqueWarningHeader')
        })
        closeOptionConfirmModal()
      }
    })
  }

  const optionConfirmModal = (
    <Modal
      title={t('fieldOptions.confirmDialogHeader')}
      focusConfirmButton
      open={isOptionConfirmModalOpen}
      onClickConfirm={handleOptionCreate}
      onClose={closeOptionConfirmModal}
    >
      {optionValue && (
        <Box direction="row">
          <Text>{t('fieldOptions.confirmDialogDescriptionFirst')}</Text>
          <Text css="font-weight: 700">&nbsp;{optionValue}&nbsp;</Text>
          <Text>{t('fieldOptions.confirmDialogDescriptionSecond')}</Text>
          <Text css="font-weight: 700">&nbsp;{optionCustomField?.display_name || optionCustomField?.name}</Text>
          <Text>{t('fieldOptions.confirmDialogDescriptionThird')}</Text>
        </Box>
      )}
    </Modal>
  )

  // Stores text input display values when a dynamic field is present.
  const [textDisplayValue, setTextDisplayValue] = useState<{ [x: number]: string | undefined }>({})

  useEffect(() => {
    if (fieldValues) {
      const displayValue: { [x: number]: string } = {}
      Object.values(fieldValues as { [x: number]: FieldValue }).forEach(fv => {
        if (fv.computed_value !== undefined) {
          displayValue[fv.custom_field_id] = fv.computed_value
        }
      })
      setTextDisplayValue(displayValue)
    }
  }, [fieldValues])

  return (
    <Box direction="column" width="100%">
      {optionConfirmModal}
      {customFields.sort(sortByDisplayOrder).map(cf => {
        const customFieldName = cf.display_name || cf.name
        const hasError = errorIds.includes(String(cf.id))
        const cfRequired = alwaysNotRequired ? false : cf.required
        // TODO: remove cf.type check once legacy SCF are migrated, ticket #WIN-2667
        const cfSlug =
          cf.type === 'SearchableCustomField' || cf.type === 'MultiSearchableCustomField'
            ? 'searchable'
            : cf.field_type.slug
        switch (cfSlug) {
          case 'text':
            if (cf.type !== 'DependentCustomField') {
              const fieldName = `${namePrefix}field_values.${cf.id}.value`
              const computedFieldName = `${namePrefix}field_values.${cf.id}.computed_value`
              const fieldValue = getValues().field_values?.[cf.id]
              const hasComputedValue = fieldValue?.computed_value !== undefined

              if (hasComputedValue) {
                const isDirty = !!dirtyFields.field_values?.[cf.id]

                const computedValueIcon = (
                  <Tooltip
                    content={dynamicVariablesTooltipLabelHelper(fieldValue?.value, 'edit.variablesReferenced', t)}
                  >
                    <Icon icon="code" color="text-light" size="medium" />
                  </Tooltip>
                )

                return (
                  <TextInput
                    key={`${cf.id}-dynamic`}
                    label={customFieldName}
                    required={cfRequired}
                    hasError={hasError}
                    readOnly={readOnly || !!cf.options?.readonly}
                    disabled={disabled}
                    endComponent={computedValueIcon}
                    value={textDisplayValue[cf.id] ?? ''}
                    onChange={evt => {
                      const value = evt.target.value
                      const displayValue = { ...textDisplayValue, [cf.id]: value }
                      setTextDisplayValue(displayValue)
                      setValue(fieldName, value, { shouldDirty: true })
                      setValue(computedFieldName, value, { shouldDirty: true })
                    }}
                    onFocus={() => {
                      const displayValue = { ...textDisplayValue, [cf.id]: getValues(fieldName) }
                      setTextDisplayValue(displayValue)
                    }}
                    onBlur={() => {
                      if (!isDirty) {
                        const displayValue = { ...textDisplayValue, [cf.id]: fieldValue?.computed_value }
                        setTextDisplayValue(displayValue)
                      }
                    }}
                  />
                )
              } else {
                return (
                  <TextInput
                    key={cf.id}
                    label={customFieldName}
                    required={cfRequired}
                    hasError={hasError}
                    readOnly={readOnly || !!cf.options?.readonly}
                    disabled={disabled}
                    defaultValue={cf.default_value as string}
                    {...register(fieldName)}
                  />
                )
              }
            }
            break
          case 'searchable':
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SearchableCustomField
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                      required={cfRequired}
                      customField={cf}
                      value={value}
                      multiSelect={cf.type === 'MultiSearchableCustomField'}
                      onChange={onChange}
                      hasError={hasError}
                    />
                  )
                }}
              />
            )
          case 'textarea':
            return (
              <Controller
                key={cf.id}
                name={`${namePrefix}field_values.${cf.id}.value`}
                control={control}
                render={({ field: { value, onChange }, formState: { defaultValues } }) => {
                  const prefix = namePrefix.substring(0, namePrefix.length - 1)
                  const defaultValue = prefix
                    ? defaultValues?.[`${prefix}`] &&
                      defaultValues?.[`${prefix}`].field_values &&
                      defaultValues?.[`${prefix}`].field_values[`${cf.id}`]?.value
                    : defaultValues?.field_values?.[cf.id]?.value

                  return (
                    <TextEditor
                      value={value ?? '<p></p>'}
                      defaultValue={defaultValue ?? '<p></p>'}
                      disabled={!!disabled}
                      hasError={hasError}
                      label={customFieldName}
                      onChange={onChange}
                      readOnly={readOnly || !!cf.options?.readonly} // TODO: should revert to only from cf? was there areason this is not also readOnly at the form level?
                      required={cfRequired}
                    />
                  )
                }}
              />
            )
          case 'select_menu':
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.field_option_id`}
                render={({ field: { value, onChange, ref, onBlur } }) => {
                  return (
                    <Select
                      inputRef={ref}
                      onBlur={onBlur}
                      value={value ? cf.field_options.find(o => o.id === value)?.id : null}
                      required={cfRequired}
                      label={customFieldName}
                      onChange={onChange}
                      options={cf.field_options.map(fo => ({ label: fo.name, value: fo.id }))}
                      hasError={hasError}
                      readOnly={readOnly || !!cf.options?.readonly}
                      disabled={disabled}
                      onCreateOption={cf.allow_field_creation ? val => showOptionConfirmModal(cf, val) : undefined}
                    />
                  )
                }}
              />
            )
          case 'radiobox':
            // need to use a controller here because CF value is a number and needs conversion to string
            // (html inputs do not accept numbers as values)
            return (
              <Controller
                name={`${namePrefix}field_values.${cf.id}.field_option_id`}
                key={cf.id}
                control={control}
                render={({ field: { name, onChange, value, onBlur, ref } }) => {
                  return cf.field_options.length > 10 ? (
                    <Select
                      inputRef={ref}
                      onBlur={onBlur}
                      value={value ? cf.field_options.find(o => o.id === value)?.id : null}
                      required={cfRequired}
                      label={customFieldName}
                      onChange={onChange}
                      options={cf.field_options.map(fo => ({ label: fo.name, value: fo.id }))}
                      hasError={hasError}
                      readOnly={readOnly || !!cf.options?.readonly}
                      disabled={disabled}
                      onCreateOption={cf.allow_field_creation ? val => showOptionConfirmModal(cf, val) : undefined}
                    />
                  ) : (
                    <RadioboxGroup
                      name={name}
                      label={customFieldName}
                      required={cfRequired}
                      direction="column"
                      onChange={e => onChange(Number(e.target.value))} // coercing here so checked===true in Grommet RadioButtonGroup
                      value={value}
                      hasError={hasError}
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                      onBlur={onBlur}
                      ref={ref}
                      options={cf.field_options.map(option => ({ value: option.id, label: option.name }))}
                      onCreateOption={cf.allow_field_creation ? val => showOptionConfirmModal(cf, val) : undefined}
                    />
                  )
                }}
              />
            )
          case 'checkboxes':
            // need to use a controller here because CF value is a number and needs conversion to string
            // (html inputs do not accept numbers as values)
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                render={({ field: { value, onChange } }) => {
                  const parsedValue = JSON.parse(value || '[]') as number[]
                  return (
                    <MultiSelectControl
                      key={cf.id}
                      label={customFieldName}
                      onCreateOption={
                        cf.allow_field_creation ? (value: string) => mutateCustomFieldOption(cf.id, value) : undefined
                      }
                      options={cf.field_options.map(option => ({
                        value: option.id,
                        option: option.name,
                        label: option.name
                      }))}
                      value={parsedValue}
                      onChange={val => {
                        const values = val?.map(v => v.value)
                        values && onChange(JSON.stringify(values))
                      }}
                      required={cfRequired}
                      hasError={hasError}
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                    />
                  )
                }}
              />
            )
          case 'datetime':
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                render={({ field: { value, onChange, ref, onBlur } }) => {
                  const timeValue = value ? new Date(value) : null
                  return (
                    <DateTimePicker
                      value={isDate(timeValue) ? timeValue : null}
                      required={cfRequired}
                      label={customFieldName}
                      onChange={date => onChange(date ? formatISO(date) : null)}
                      hasError={hasError}
                      disabled={disabled}
                      readOnly={readOnly || !!cf.options?.readonly}
                      inputRef={ref}
                      onBlur={onBlur}
                      timezone={timezone}
                    />
                  )
                }}
              />
            )
          case 'temporary':
            // TODO: check with integrations if this type ever made its way to production and if
            // any clients are using it
            return (
              <Box key={cf.id} margin={{ top: 'medium' }}>
                <TextInput
                  {...register(`${namePrefix}field_values.${cf.id}.value`)}
                  label={customFieldName}
                  required={cfRequired}
                  disabled={disabled || readOnly || !!cf.options?.readonly} // TODO: distinguish between disabled and readonly
                />
              </Box>
            )
          case 'user_select':
            return (
              <Controller
                key={cf.id}
                control={control}
                name={`${namePrefix}field_values.${cf.id}.value`}
                // the custom field is stored in the database as json stringified array of ids
                render={({ field: { value: stringifiedValue, onChange, onBlur, ref } }) => {
                  const userIds = JSON.parse(stringifiedValue || '[]') as number[]

                  return (
                    <CustomFieldUserSelect
                      required={cfRequired}
                      label={customFieldName}
                      accountId={account?.id}
                      value={userIds}
                      customFieldUsers={customFieldUsers}
                      onChange={value => (value ? onChange(JSON.stringify(value)) : null)}
                      disabled={disabled}
                      inputRef={ref}
                      onBlur={onBlur}
                      readOnly={readOnly || !!cf.options?.readonly}
                    />
                  )
                }}
              />
            )
          case 'endpoint':
            // TODO: Remove. Will be deprecated soon
            return (
              <TextInput
                {...register(`${namePrefix}field_values.${cf.id}.value`)}
                key={cf.id}
                label={customFieldName}
                required={cfRequired}
                readOnly={readOnly || !!cf.options?.readonly}
                disabled={disabled}
              />
            )
          case 'task_picker':
            const fieldName = `${namePrefix}field_values.${cf.id}.value`
            let taskInternalId = getValues(`${namePrefix}field_values.${cf.id}.value`)
            taskInternalId = typeof taskInternalId !== 'object' ? [taskInternalId] : taskInternalId
            const possibleTasks = Object.keys(taskLookup).map(id => taskLookup[id])

            return (
              <Fragment key={cf.id}>
                {taskTypeLookup && streamLookup && (
                  <TaskSelectField
                    label={customFieldName}
                    name={fieldName}
                    tasks={possibleTasks}
                    taskTypeLookup={taskTypeLookup}
                    streamLookup={streamLookup}
                    valueKey={'internal_id'}
                    value={taskInternalId}
                    single
                    placeholderValue={t('fields.taskPicker.placeholderValue')}
                    placeholder={t('fields.taskPicker.placeholder')}
                    required={cfRequired}
                    disabled={disabled}
                    readOnly={readOnly || !!cf.options?.readonly}
                    onChange={value => (value ? JSON.stringify(value) : null)}
                  />
                )}
              </Fragment>
            )
          default:
            return unhandledCFTypeError(cf.field_type.slug) // this ensures we handle all field_type slugs in switch statement
        }
      })}
    </Box>
  )
}

const unhandledCFTypeError = (type: CustomFieldDisplayType): never => {
  throw new Error(`Custom field type ${type} not handled. If this is expected please handle it by returning null`)
}

const sortByDisplayOrder = (a: CustomField, b: CustomField) => (a.order || 0) - (b.order || 0)
