import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { toCamelCase, toSnakeCase } from '@cutover/api'
import {
  Box,
  Button,
  Checkbox,
  CodeEditor,
  IconButton,
  InputTypes,
  Modal,
  resolveColor,
  Select,
  Text,
  TextInput,
  useTheme
} from '@cutover/react-ui'
import { PollingConditionMapping } from './polling-condition-mapping'
import { ResponseMapping } from './response-mapping'
import { isDependentSettings } from '../action-items/integration-action-item-form-helper'
import { IntegrationActionItemFormType } from '../action-items/integration-action-item-types'
import { ConnectProjectNames } from '../integrations/integration-setting-form-helper'
import { IntegrationSettingFormType } from '../integrations/integration-setting-types'
import { IntegrationSettingOption, IntegrationSettingOptionsConfig, useLanguage } from 'main/services/hooks'
import { IntegrationActionItem } from 'main/services/queries/types'
import { ConfigModel, CurrentUserModel } from 'main/data-access'

export type CustomFieldOptionType = {
  label: string
  value: string | number
}

export type IntegrationFormSettingFieldsProps = {
  settings?: IntegrationSettingOptionsConfig
  customFieldOptions?: CustomFieldOptionType[]
  group?: string | unknown
  actionItems?: IntegrationActionItem[]
  isDataSource?: boolean
}

export type FieldSettingType = IntegrationActionItemFormType | IntegrationSettingFormType

export const IntegrationFormSettingFields = ({
  settings,
  customFieldOptions,
  group,
  actionItems,
  isDataSource
}: IntegrationFormSettingFieldsProps) => {
  const {
    formState: { errors },
    getValues
  } = useFormContext<IntegrationSettingFormType>()

  // @ts-ignore TODO: update to correct type
  const { id, integration_action, oauth_user } = getValues()
  const currentUser = CurrentUserModel.useGet()
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'form' })
  const theme = useTheme()
  const [searchParams] = useSearchParams()

  const navigateToOAuthUrl = () => {
    if (integration_action) {
      const clientType = toSnakeCase(integration_action.split('::')[1])
      const url = `/api/integrations/oauth/authentications/${clientType}/authorize?location=${encodeURIComponent(
        location.href
      )}&integration_action_item_id=${searchParams.get('integration_action_item_id')}&user_id=${currentUser.id}`
      window.location.assign(url)
    }
  }

  const settingFields =
    settings &&
    Object.entries(toCamelCase(settings)).map(([settingKey, setting], index) => {
      const error = errors?.settings?.[settingKey as keyof FieldSettingType]

      // Do not render the component if a dependency is defined and the value
      // does not match the selected value of the dependent_setting.
      if (!isDependentSettings(setting, getValues) && !setting.dependencyVisibility) {
        return
      }

      if (group && setting.group !== group) {
        return
      }

      switch (setting.fieldType) {
        case 'code_editor':
          return <CodeEditorSetting key={index} setting={setting} settingKey={settingKey} />
        case 'custom_field_mapping':
          return (
            <ResponseMappingSetting
              key={index}
              setting={setting}
              settingKey={settingKey}
              customFieldOptions={customFieldOptions}
            />
          )
        case 'polling_condition_mapping':
          return (
            <PollingConditionMappingSetting
              key={index}
              setting={setting}
              settingKey={settingKey}
              customFieldOptions={customFieldOptions}
            />
          )
        case 'text_input':
          return (
            <TextInputSetting
              key={index}
              setting={setting}
              settingKey={settingKey}
              error={error}
              required={setting.required && isDependentSettings(setting, getValues)}
            />
          )
        case 'number_input':
          return (
            <TextInputSetting
              key={index}
              setting={setting}
              required={setting.required}
              settingKey={settingKey}
              error={error}
              type="number"
            />
          )
        case 'select':
          return (
            <SelectSetting
              key={index}
              setting={setting}
              settingKey={settingKey}
              error={error}
              actionItems={actionItems}
            />
          )
        case 'checkbox':
          if (!isDataSource && integration_action?.split('::')[1] === 'MicrosoftGraph') {
            return
          }
          return <CheckboxSetting key={index} setting={setting} settingKey={settingKey} />
        case 'oauth_button':
          if (!isDataSource && id && !oauth_user?.has_session) {
            return (
              <Button
                key={index}
                label={setting.label}
                secondary
                full
                onClick={navigateToOAuthUrl}
                disabled={Boolean(!id)}
                css={`
                  margin-top: 16px;
                `}
              />
            )
          } else if (oauth_user?.has_session) {
            return (
              <Box
                key={index}
                direction="row"
                css={`
                  margin-top: 16px;
                `}
              >
                <Text
                  as="span"
                  weight={400}
                  css={`
                    font-size: 13px;
                    color: ${resolveColor('text-light', theme)};
                  `}
                >
                  {t('functionalUser')}:
                </Text>
                <Text
                  as="span"
                  weight={400}
                  css={`
                    padding-left: 4px;
                  `}
                >
                  {oauth_user.name}
                </Text>
              </Box>
            )
          }
      }
    })

  return <>{settingFields}</>
}

export type SettingFieldProps = {
  setting: IntegrationSettingOption
  settingKey: string
  error?: any
  customFieldOptions?: CustomFieldOptionType[]
  actionItems?: IntegrationActionItem[]
}

const CodeEditorSetting = ({ setting, settingKey }: SettingFieldProps) => {
  const theme = useTheme()
  const [codeEditorModalOpen, setCodeEditorModalOpen] = useState(false)
  const { control } = useFormContext()
  return (
    <Box direction="column">
      <Box
        direction="row"
        css={`
          line-height: 24px;
          font-size: 13px;
          font-weight: 400;
          color: ${resolveColor('text-light', theme)};
        `}
      >
        {setting.label}
        <IconButton
          label="Open in new window"
          icon="open-new"
          size="small"
          onClick={() => setCodeEditorModalOpen(true)}
        />
      </Box>
      <Controller
        name={`settings.${settingKey}`}
        control={control}
        defaultValue={setting.default || ''}
        render={({ field: { onChange, value } }) => (
          <CodeEditor
            onChange={value => onChange(value)}
            value={value as string | undefined}
            defaultLanguage="json"
            resize="vertical"
            showCutoverCodeCompletion
            minHeight={100}
          />
        )}
      />
      <Modal
        title={setting.label || ''}
        open={codeEditorModalOpen}
        onClose={() => setCodeEditorModalOpen(false)}
        hideFooter={true}
      >
        <Controller
          name={`settings.${settingKey}`}
          control={control}
          defaultValue={setting.default || ''}
          render={({ field: { onChange, value } }) => (
            <CodeEditor
              onChange={value => onChange(value)}
              value={value as string | undefined}
              defaultLanguage="json"
              resize="vertical"
              showCutoverCodeCompletion
              minHeight={600}
            />
          )}
        />
      </Modal>
    </Box>
  )
}

const ResponseMappingSetting = ({ setting, settingKey, customFieldOptions }: SettingFieldProps) => {
  const theme = useTheme()
  const { control } = useFormContext()
  return (
    <Box direction="column" margin={{ bottom: '16px' }}>
      <Text
        as="span"
        weight={400}
        css={`
          font-size: 13px;
          padding-bottom: 4px;
          color: ${resolveColor('text-light', theme)};
        `}
      >
        {setting.label}
      </Text>
      {customFieldOptions && (
        <Controller
          name={`settings.${settingKey}`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <ResponseMapping customFieldOptions={customFieldOptions} value={value} onChange={onChange} />
          )}
        />
      )}
    </Box>
  )
}

const PollingConditionMappingSetting = ({ setting, settingKey, customFieldOptions }: SettingFieldProps) => {
  const theme = useTheme()
  const { control } = useFormContext()
  return (
    <Box direction="column" margin={{ top: '16px' }}>
      <Text
        as="span"
        weight={400}
        css={`
          font-size: 13px;
          color: ${resolveColor('text-light', theme)};
        `}
      >
        {setting.label}
      </Text>
      {customFieldOptions && (
        <Controller
          name={`settings.${settingKey}`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <PollingConditionMapping customFieldOptions={customFieldOptions} value={value} onChange={onChange} />
          )}
        />
      )}
    </Box>
  )
}

const TextInputSetting = ({
  required,
  setting,
  settingKey,
  error,
  type = 'text'
}: SettingFieldProps & { type?: InputTypes; required?: boolean }) => {
  const { register } = useFormContext()
  return (
    <TextInput
      {...register(`settings.${settingKey}`, { required })}
      label={setting.label}
      defaultValue={setting.default || ''}
      required={required}
      hasError={Boolean(error)}
      tooltipText={setting.tooltip}
      type={type}
      min={setting.min}
    />
  )
}

const SelectSetting = ({ setting, settingKey, error, actionItems }: SettingFieldProps) => {
  const { control } = useFormContext()
  const isConnectSettingsEnabled = ConfigModel.useIsFeatureEnabled('connect_settings')
  const [searchParams] = useSearchParams()
  const defaultValue = setting.default || null
  const integrationActionId = searchParams.get('integration_action_item_id')
  const actionItem = actionItems?.find(iai => iai.id === Number(integrationActionId))

  let fieldOptions = setting.fieldOptions ? setting.fieldOptions : []

  if (settingKey === 'connectProject' && isConnectSettingsEnabled) {
    fieldOptions = [...ConnectProjectNames(actionItem)]
  }

  const selectOptions = fieldOptions?.map(option => ({
    label: option,
    value: option
  }))

  return (
    <Controller
      name={`settings.${settingKey}`}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <Select
          onChange={option => onChange(option.value)}
          required={setting.required}
          hasError={Boolean(error)}
          inputRef={ref}
          // TODO: shouldn't be using defaultValue AND value but there is more going on with form state in the components that
          // use these fields
          defaultValue={defaultValue}
          label={setting.label}
          value={value}
          options={selectOptions}
          helpText={setting.tooltip}
        />
      )}
    />
  )
}

const CheckboxSetting = ({ setting, settingKey }: SettingFieldProps) => {
  const { register, getValues } = useFormContext()
  const value = typeof getValues(`settings.${settingKey}`) === 'string' ? false : getValues(`settings.${settingKey}`)
  return (
    <Box direction="column" margin={{ bottom: '16px', left: '-6px' }}>
      <Checkbox {...register(`settings.${settingKey}`)} checked={value} label={setting.label} />
    </Box>
  )
}
